import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import _ from 'lodash'

import { CLUSTERS, POPS_INFO } from '../utils/populations'
import AncestryMap from './ancestry-map'

let counter = 1
const clusterIndex = CLUSTERS.reduce((clidx, cluster) => {
  clidx[cluster[0]] = clidx[cluster[0]] ? clidx[cluster[0]] : ++counter
  return clidx
}, {})

export const AppContext = React.createContext()

function AppWithContext (props) {
  const { report, isPreview } = props

  const [cultures, setCultures] = useState([])

  useEffect(() => {
    if (_.isEmpty(report)) return
    const reportCopy = _.cloneDeep(report)
    const popNames = Object.keys(reportCopy.populations)
    let idx = 0
    for (const popName of popNames) {
      const clust = CLUSTERS.find(c => c[1] === popName)
      reportCopy.populations[popName] = {
        ...POPS_INFO[popName],
        percentage: reportCopy.populations[popName],
        cluster: clusterIndex[clust[0]],
        idx
      }
      idx++
    }
    setCultures(reportCopy)
  }, [report])

  const previewContextValue = {
    cultures,
    isMobile,
    report,
    isPreview
  }

  let content = null
  if (previewContextValue.cultures?.populations) {
    content = (
      <div className='map'>
        <AncestryMap />
      </div>
    )
  }
  return (
    <AppContext.Provider value={previewContextValue}>
      {content}
    </AppContext.Provider>
  )
}

export default AppWithContext
