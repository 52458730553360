// https://stackoverflow.com/questions/38820724/how-to-display-leaflet-markers-near-the-180-meridian/

import React, { useContext, useState, useEffect } from 'react'
import L from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
// import MarkerClusterGroup from 'react-leaflet-markercluster'
import _ from 'lodash'
import { AppContext } from './app-context'
import { formatPercentage } from '../utils'

function AncestryCluster (props) {
  const { zoom } = props
  const {
    cultures,
    isMobile
  } = useContext(AppContext)

  const [iconSize, setIconSize] = useState(16)
  const [clusters, setClusters] = useState(null)

  useEffect(() => {
    // if (zoom < 1) {
    //   setIconSize(20)
    // } else
    if (isMobile) {
      setIconSize(6)
    } else {
      setIconSize(10)
    }
  }, [zoom, isMobile])

  useEffect(() => {
    if (_.isEmpty(cultures) || _.isEmpty(cultures.populations)) {
      if (clusters !== null) setClusters(null)
    } else {
      const popNames = Object.keys(cultures.populations)
      const cltsNew = popNames.reduce((clsts, p) => {
        const pop = cultures.populations[p]
        clsts[pop.cluster] = clsts[pop.cluster] || []
        clsts[pop.cluster].push(pop)
        return clsts
      }, {})
      setClusters(cltsNew)
    }
  }, [cultures])

  if (!cultures || !clusters) return null

  const getDefaultMarker = (pop, iconSize) => {
    const iconSizePx = iconSize + (30 * pop.percentage)
    if (formatPercentage(pop.percentage) <= 0) return null
    // console.log([culture.Lat, culture.Long])
    const iconMarker = new L.divIcon({
      html: `<div style="width: 100%; height:100%; background-color: ${pop.color};" ><div>`,
      className: 'custom-maker-icon',
      iconSize: L.point(iconSizePx, iconSizePx, true)
    })
    return (
      <Marker
        key={'culture-' + pop.idx}
        position={[pop.coordinates[1], pop.coordinates[0]]}
        icon={iconMarker}
        // onclick={() => console.log('clicked', pop)}
      >
        <Popup>{pop.displayName}: {formatPercentage(pop.percentage)}%</Popup>
      </Marker>
    )
  }

  return Object.keys(clusters).map((clustKey, idx) => {
    const cluster = clusters[clustKey]
    // if (cluster.length > 1) {
    //   return (
    //     <MarkerClusterGroup key={'clustergroup-' + idx} showCoverageOnHover={false}>
    //       {cluster.map((pop, idx) => getDefaultMarker(pop, iconSize))}
    //     </MarkerClusterGroup>
    //   )
    // }
    // return getDefaultMarker(cluster[0], iconSize)
    return cluster.map(pop => getDefaultMarker(pop, iconSize))
  })
}

export default AncestryCluster
