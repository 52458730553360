import './App.scss'
import React, { useEffect, useState } from 'react'
// import * as $ from 'jquery'
// import _ from 'lodash'

import utils from './utils/tree.js'
import { CLUSTERS, POPS_INFO } from './utils/populations'
import Sunburst from './components/sunburst'
import Table from './components/table'
// import StackedBarChart from './components/stacked-bar-chart'
import AppWithContext from './components/app-context'

// import regionsExamples from './examples/regions-example.tsv'
// import popsExamples from './examples/pops-example.tsv'

let interval

// try {
//   const url = new URL(document.location.href)
//   const analysisId = url && url.searchParams ? url.searchParams.get('analysis_id') : null
//   console.log(analysisId)
// } catch (e) {
// }

function App (props) {
  // console.log('in APP', props)
  const { report = null } = props
  const [active, setActive] = useState(null)
  const [sizeUpdate, setSizeUpdate] = useState(null)
  // const [file, setFile] = useState(regionsExamples)
  const [tree, setTree] = useState(null)
  // const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    interval = setInterval(() => {
      const message = JSON.stringify({
        height: document.body.clientHeight
      })
      window.parent.postMessage(message, '*')
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    let treeInner = null
    if (report && report.populations) {
      treeInner = utils.build(report.populations, CLUSTERS, POPS_INFO)
    }
    setTree(treeInner)
  }, [report])
  // useEffect(() => {
  //   window.addEventListener('resize', resizeHandler)
  //   return () => window.removeEventListener('resize', resizeHandler)
  // }, [])
  // const activeStyle = ' bg-blue-500 text-white font-bold'
  // const unactiveStyle = ' text-blue-500 '
  // const commonStyle = 'px-4 py-1 text-center border-blue-500 border-2'

  // const popButtonClass = commonStyle + ' rounded-l-lg' + (file === regionsExamples ? activeStyle : unactiveStyle)
  // const regionButtonClass = commonStyle + ' rounded-r-lg' + (file === popsExamples ? activeStyle : unactiveStyle)

  return (
    <div className='app box-border'>
      <div className='bg-gray-100 py-6 flex flex-col justify-center sm:py-12 box-border'>
        <div className='relative py-3 sm:max-w-xl md:max-w-2xl lg:max-w-4xl sm:mx-auto box-border'>
          <div className='absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl' />
          <div className='relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20'>
            <div className='mx-auto'>
              <p className='text-base md:text-4xl sm:text-2xl text-center'>
                K30
              </p>
              <div className='divide-y divide-gray-200'>
                <div className='py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7'>
                  <p>
                    The K30 Admixture Calculator determines your genetic similarity to various worldwide populations based on shared allele frequencies.
                  </p>
                  <p>
                    <strong> Your K30 Ancestry Composition Results </strong>
                    <br />
                    This calculator returns a list of your population percentages arranged in a hierarchy from the regional level.
                    <br />
                    How the K30 calculator computes your ancestral breakdown?
                    <br />
                    To determine your ancestral breakdown, the K30 calculator uses the ADMIXTURE algorithm which uses genetic data to infer how populations are related to one another.
                  </p>
                </div>
              </div>
            </div>
            <div className='p-1.5 box-border'>
              {tree && <Sunburst className='box-border' tree={tree} setActive={setActive} sizeUpdate={setSizeUpdate} />}
            </div>
            <div className='mt-8 box-border'>
              {report && <Table report={report} />}
            </div>
            <div className='mt-8 box-border'>
              {report && <AppWithContext report={report} isPreview={false} />}
            </div>
            {/* <div className='flex flex-col content-center mt-8'>
              <p className='text-gray-500 text-center font-semi-bold'>
                Explore typical compositions of various nationalities and how they compare to you.
              </p>
              <p className='italic text-gray-400 text-center'>
                The regional chart shows average regional compositions for different nationaloties, e.g: Polish are 99.8% Europeans. The Population chart shows average poluation mixes of different nationalities, e.g: Finns are 63% Baltic.
              </p>
            </div>
            <div className='mt-8 box-border'>
              <div className='flex justify-center mb-8'>
                <button className={popButtonClass} onClick={() => setFile(regionsExamples)}>Region view</button>
                <button className={regionButtonClass} onClick={() => setFile(popsExamples)}>Population view</button>
              </div>
              <StackedBarChart className='border-solid border-2 border-light-gray-500 rounded-md px-2' file={file} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
