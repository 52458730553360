import React from 'react'
import { CLUSTERS, POPS_INFO, getDisplayName } from '../utils/populations'
import { formatPercentage } from '../utils'

function getPopulations (region) {
  return CLUSTERS.filter(c => c[0] === region).map(c => c[1])
}

function sortObjectOnValues (obj) {
  return Object.entries(obj)
    .sort((a, b) => a[1] >= b[1] ? -1 : 1)
    .reduce((acc, pair) => {
      acc[pair[0]] = pair[1]
      return acc
    }, {})
}

function Region (props) {
  const { region, report } = props

  const populations = report.populations
  const populationsNames = getPopulations(region)
  const popsResults = populationsNames.reduce((acc, p) => {
    acc[p] = populations[p]
    return acc
  }, {})
  const sortedPopulationNames = Object.keys(sortObjectOnValues(popsResults))
  const rowspan = populationsNames.length
  const totalPercentage = populationsNames.reduce((t, p) => t + popsResults[p], 0)

  return (
    <>
      {sortedPopulationNames.map((p, idx) => {
        return (
          <tr key={p} className='py-8'>
            {idx === 0 &&
              <td rowSpan={rowspan} className='text-sm font-medium items-center text-gray-900 text-center'>
                <div className='flex items-center'>
                  <div className='ml-1 mr-3 mt-2 mb-2 h-8 w-1' style={{ width: '1rem', height: '1rem', borderRadius: '50%', backgroundColor: POPS_INFO[region].color }} />
                  {getDisplayName(region)}
                </div>
              </td>}
            {idx === 0 &&
              <td rowSpan={rowspan} className='text-sm font-medium text-right text-gray-500'>
                {formatPercentage(totalPercentage)}%
              </td>}
            <td className='text-xs px-2 pl-5 text-gray-600'>
              <div className='flex items-center'>
                <div className='mr-3 mt-2 mb-2 h-5 w-1' style={{ width: '0.8rem', height: '0.8rem', borderRadius: '50%', backgroundColor: POPS_INFO[region].color }} />
                {getDisplayName(p)}
              </div>
            </td>
            <td className='text-sm text-right pr-5 text-gray-500'>
              {formatPercentage(populations[p])}%
            </td>
          </tr>
        )
      })}
    </>
  )
}

class Table extends React.Component {
  render () {
    const report = this.props.report
    const regions = this.props.report.regions

    const populations = report.populations
    const regionNamesBeforeSort = Object.keys(regions)
    const correctPercentagesPerRegion = {}
    regionNamesBeforeSort.forEach(r => {
      const populationsNames = getPopulations(r)
      const totalPercentage = populationsNames.reduce((t, p) => t + populations[p], 0)
      correctPercentagesPerRegion[r] = totalPercentage
    })

    const sortedRegions = sortObjectOnValues(correctPercentagesPerRegion)
    const regionNames = Object.keys(sortedRegions)
    return (
      <div className='shadow border-b border-gray-200 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center'>
                Region
              </th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider' />
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center'>Population</th>
              <th scope='col' className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider' />
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {regionNames.map(r => <Region region={r} report={report} key={r} />)}
          </tbody>
        </table>
      </div>
    )
  }

  componentDidMount () { }

  componentWillUnmount () { }

  componentDidUpdate () { }
}

export default Table
