import utils from '../utils/tree.js'
import { formatPercentage } from '../utils'
import * as d3 from 'd3'

// Dimensions of sunburst.
export function createSunBurst (tree, colors, width, clickCallback) {
  // var width = document.body.clientWidth
  // let textWidthMultiplier = 10
  if (width > 1000) {
    width = 750
  }
  if ((width > 510) && (width < 1000)) {
    width = 450
  }
  if (width < 750) {
    // textWidthMultiplier = 7
  }
  const height = width
  const radius = Math.min(width, height) / 2

  // // Breadcrumb dimensions: width, height, spacing, width of tip/tail.
  // var b = {
  //   w: 75, h: 30, s: 3, t: 10,
  //   collapseWidth: 15,
  //   computeWidth(text, i, totalNumberOfBreadCrumbs) {
  //   return i<(totalNumberOfBreadCrumbs-1) ? this.collapseWidth : text.length * textWidthMultiplier
  //   }
  // }
  /* Positioned the explanation with css */
  // $('#explanation').css('top', `${(width/2)-(100/2)}px`)
  // $('#explanation').css('left', `${(width/2)-(140/2)}px`)

  // Total size of all segments; we set this later, after loading the data.
  // var totalSize = 0

  d3.select('svg').remove()
  const vis = d3.select('#chart')
    .append('svg:svg')
    .attr('width', width)
    .attr('height', height)
    .append('svg:g')
    .attr('id', 'container')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')

  const partition = d3.partition()
    .size([2 * Math.PI, radius * radius])

  const arc = d3.arc()
    .startAngle(d => d.x0)
    .endAngle(d => d.x1)
    .innerRadius(d => Math.sqrt(d.y0))
    .outerRadius(d => Math.sqrt(d.y1))

  // Main function to draw and set up the visualization, once we have the data.
  function createVisualization (json, width, colors) {
    // Basic setup of page elements.
    // initializeBreadcrumbTrail(width)
    drawLegend(colors)
    d3.select('#togglelegend').on('click', toggleLegend)

    // Bounding circle underneath the sunburst, to make it easier to detect
    // when the mouse leaves the parent g.
    vis.append('svg:circle')
      .attr('r', radius)
      .style('opacity', 0)

    // Turn the data into a d3 hierarchy and calculate the sums.
    const root = d3.hierarchy(json)
      .sum(d => d.size ? d.size : 0)
      .sort((a, b) => b.value - a.value)

    // For efficiency, filter nodes to keep only those large enough to see.
    const nodes = partition(root).descendants()
      .filter(d => d.x1 - d.x0 > 0.005) // 0.005 radians = 0.29 degrees

    // const path =
    vis.data([json]).selectAll('path')
      .data(nodes)
      .enter()
      .append('svg:path')
      .attr('display', d => d.depth ? null : 'none')
      .attr('d', arc)
      .attr('fill-rule', 'evenodd')
      .style('fill', d => d.data.color)
      .style('opacity', 1)
      .on('mouseover', mouseover)

    // Add the mouseleave handler to the bounding circle.
    d3.select('#container').on('mouseleave', mouseleave)

    // Get total size of the tree = value of root node from partition.
    // totalSize = path.datum().value
  }

  // Fade all but the current sequence, and show it in the breadcrumb trail.
  function mouseover (d) {
    // console.log(d)
    if (!d.data) return

    clickCallback(d.data.cluster)

    const percentageString = `${formatPercentage(+d.data.percentage, true)}%`
    d3.select('#percentage')
      .text(percentageString)

    d3.select('#explanation-text')
      .text(d.data.displayName)

    d3.select('#explanation-desc')
      .text(d.data.description ? d.data.description : '')

    d3.select('#explanation')
      .style('visibility', '')

    const sequenceArray = d.ancestors().reverse()
    sequenceArray.shift() // remove root node from the array
    // updateBreadcrumbs(sequenceArray, percentageString)

    // Fade all the segments.
    vis.selectAll('path')
      .style('opacity', 0.3)

    // Then highlight only those that are an ancestor of the current segment.
    vis.selectAll('path')
      .filter(node => sequenceArray.indexOf(node) >= 0)
      .style('opacity', 1)
  }

  // Restore everything to full opacity when moving off the visualization.
  function mouseleave (d) {
    // console.log('mouseleave', d)
    // Hide the breadcrumb trail
    // d3.select('#trail')
    //     .style('visibility', 'hidden')

    // Transition each segment to full opacity and then reactivate it.
    vis.selectAll('path')
      .transition()
      .duration(600)
      .style('opacity', 1)

    d3.select('#explanation')
      .style('visibility', 'hidden')
  }

  // function initializeBreadcrumbTrail (width) {
  //   // Add the svg area.
  //   const trail = d3.select('#sequence').append('svg:svg')
  //       .attr('width', width)
  //       .attr('height', 50)
  //       .attr('id', 'trail')
  //   // Add the label at the end, for the percentage.
  //   trail.append('svg:text')
  //     .attr('id', 'endlabel')
  //     .style('fill', '#000')
  // }

  // Generate a string that describes the points of a breadcrumb polygon.
  // function breadcrumbPoints(d, i, numberOfNodes) {
  //   let width = b.computeWidth(d.data.displayName, i, numberOfNodes)
  //   var points = []
  //   points.push('0,0');  // left lower point
  //   points.push(width + ',0'); // right lower point
  //   points.push(width + b.t + ',' + (b.h / 2));  // right arrow point
  //   points.push(width + ',' + b.h); // right upper point
  //   points.push('0,' + b.h);// left upper point
  //   if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
  //     points.push(b.t + ',' + (b.h / 2));// left arrow point
  //   }
  //   return points.join(' ')
  // }

  // Update the breadcrumb trail to show the current sequence and percentage.
  // function updateBreadcrumbs(nodeArray, percentageString) {
  //   // Data join; key function combines name and depth (= position in sequence).
  //   var collapse = true
  //   var numberOfNodes = nodeArray.length
  //
  //   var trail = d3.select('#trail')
  //       .selectAll('g')
  //       .data([], d => d.data.displayName )
  //
  //   // Remove exiting nodes.
  //   trail.exit().remove()
  //
  //   // Add breadcrumb and label for entering nodes.
  //   var entering = trail.data(nodeArray, d => d.data.displayName )
  //       .enter()
  //       .append('svg:g')
  //
  //   entering.append('svg:polygon')
  //       .attr('points', (d,i) => breadcrumbPoints(d, i, numberOfNodes) )
  //       .style('fill', d => d.data.color )
  //
  //   let text = entering.append('svg:text')
  //       // .attr('x', (b.w + b.t) / 2)
  //       .attr('y', b.h / 2)
  //       .attr('dy', '0.35em')
  //       .attr('text-anchor', 'middle')
  //       .text( (d,i) => ( (numberOfNodes-1) > i ) ? '' : d.data.displayName)
  //
  //   text.each( (d,i) => {
  //     let width = (b.t + b.computeWidth(d.data.displayName, i, numberOfNodes) ) / 2
  //     text.attr('x', width)
  //   })
  //
  //
  //   // Merge enter and update selections; set position for all nodes.
  //   var textWidths = {}
  //   entering.merge(trail).attr('transform', (d, i) => {
  //     let width = b.s + b.computeWidth(d.data.displayName, i, numberOfNodes)
  //     textWidths[i] = width
  //     let x = 0
  //     // console.log(i,textWidths)
  //     if (i > 0) {
  //       for (let j = (i - 1); j >= 0; j--) {
  //         if (textWidths[j]) {
  //           // console.log('ADDDING',i,x,textWidths[j])
  //           x += textWidths[j]
  //         }
  //       }
  //     }
  //     // let x = (b.w + b.s + (d.data.name.length * 4))
  //     return 'translate(' + x + ', 0)'
  //   })
  //
  //   var totalWidth = 0
  //   for (var w in textWidths) {
  //     totalWidth += textWidths[w]
  //   }
  //   totalWidth = totalWidth + 40
  //   // Now move and update the percentage at the end.
  //   d3.select('#trail').select('#endlabel')
  //       .attr('x', totalWidth)
  //       .attr('y', b.h / 2)
  //       .attr('dy', '0.35em')
  //       .attr('text-anchor', 'middle')
  //       .text(percentageString)
  //
  //   // Make the breadcrumb trail visible, if it's hidden.
  //   d3.select('#trail')
  //       .style('visibility', '')
  // }

  function drawLegend (colors) {
    // Dimensions of legend item: width, height, spacing, radius of rounded rect.
    const li = {
      w: 75, h: 30, s: 3, r: 3
    }

    const legend = d3.select('#legend').append('svg:svg')
      .attr('width', li.w)
      .attr('height', d3.keys(colors).length * (li.h + li.s))

    const g = legend.selectAll('g')
      .data(d3.entries(colors))
      .enter()
      .append('svg:g')
      .attr('transform', (d, i) => 'translate(0,' + i * (li.h + li.s) + ')')

    g.append('svg:rect')
      .attr('rx', li.r)
      .attr('ry', li.r)
      .attr('width', li.w)
      .attr('height', li.h)
      .style('fill', d => d.value)

    g.append('svg:text')
      .attr('x', li.w / 2)
      .attr('y', li.h / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text(d => d.key)
  }

  function toggleLegend () {
    const legend = d3.select('#legend')
    if (legend.style('visibility') === 'hidden') {
      legend.style('visibility', '')
    } else {
      legend.style('visibility', 'hidden')
    }
  }

  const json = treeToNodes(tree)
  createVisualization(json, width, colors)
}

function treeToNodes (tree) {
  tree.cluster = tree
  if (tree.name !== 'root' && !(tree.children && utils.shouldShowChildren(tree))) {
    tree.size = tree.percentage
  }
  tree.children.forEach(c => treeToNodes(c))
  return tree
}
