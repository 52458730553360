import React, { useState, useContext, useEffect } from 'react'
import { Map, TileLayer, ZoomControl } from 'react-leaflet'
import AncestryCluster from './ancestry-clusters'
import { AppContext } from './app-context'

const originalCenter = [18, 0.42]

function AncestryMap (props) {
  const [zoom, setZoom] = useState(2)
  const [center, setCenter] = useState(originalCenter)

  const {
    isMobile
  } = useContext(AppContext)

  useEffect(() => {
    setZoom(isMobile ? 1 : 2)
  }, [isMobile])

  return (
    <Map
      center={center}
      zoom={zoom}
      zoomControl={false}
      maxZoom={12}
      minZoom={isMobile ? 0 : 2}
      tap={false}
      onViewportChanged={(e) => zoom !== e.zoom ? setZoom(e.zoom) : null}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <ZoomControl position='bottomright' />
      <AncestryCluster zoom={zoom} />
    </Map>
  )
}

export default AncestryMap
