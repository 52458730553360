export const CLUSTERS = [
  ['W_Asian_&_N_African', 'Asian-West1'],
  ['W_Asian_&_N_African', 'Asian-West2'],
  ['W_Asian_&_N_African', 'Asian-Southwest'],
  ['W_Asian_&_N_African', 'African-North'],

  ['C&SC_Asian', 'Asian-Central-West1'],
  ['C&SC_Asian', 'Asian-Central-West2'],
  ['C&SC_Asian', 'Asian-Central-East1'],
  ['C&SC_Asian', 'Asian-Central-East2'],
  ['C&SC_Asian', 'Asian-Central-East3'],

  ['S_Asian', 'Asian-Southcentral'],
  ['S_Asian', 'Asian-South'],

  ['E_Asian', 'Asian-East'],
  ['E_Asian', 'Asian-Southeast'],

  ['European', 'Slavic-Eastern'],
  ['European', 'Slavic-Southern'],
  ['European', 'Baltic'],
  ['European', 'European-Northwest'],
  ['European', 'European-West'],
  ['European', 'Scandanavian'],

  ['Mediteranean', 'Mediteranean-Eastern'],
  ['Mediteranean', 'Mediteranean-Western'],

  ['Siberian&NativeAm', 'Siberian-West'],
  ['Siberian&NativeAm', 'Siberian-Central'],
  ['Siberian&NativeAm', 'Siberian-East'],

  ['Siberian&NativeAm', 'Polar'],
  ['Siberian&NativeAm', 'Native-American-S'],
  ['Siberian&NativeAm', 'Native-American-C'],

  ['Oceania', 'Oceanian'],

  ['African', 'African-East'],
  ['African', 'African-West']
]
/* eslint-disable quote-props */
export const POPS_INFO = {
  'W_Asian_&_N_African': {
    color: '#336B24',
    displayName: 'West-Asia & North-Africa'
  },
  'C&SC_Asian': {
    displayName: 'Central-Asia',
    color: '#240046'
  },
  'S_Asian': {
    color: '#A8551C',
    displayName: 'South-Asia'
  },
  'E_Asian': {
    color: '#FDD468',
    displayName: 'East-Asia'
  },
  'European': {
    color: '#3A6CCD',
    displayName: 'Europe'
  },
  'Mediteranean': {
    color: '#9A446D',
    displayName: 'Mediterranean'
  },
  'Siberian&NativeAm': {
    color: '#e92056',
    displayName: 'Siberia & America'
  },
  'Oceania': {
    color: '#B58523',
    displayName: 'Oceania'
  },
  'African': {
    color: '#991B11',
    displayName: 'Africa'
  },
  'Oceanian': {
    color: '#B58523',
    displayName: 'Australians, Papuans',
    coordinates: [142.4008456184320, -11.907929507224337]
  },
  'Asian-West1': {
    color: '#244519',
    displayName: 'Asian-West1 (Georgians)',
    coordinates: [43.41121, 42.45911]
  },
  'Asian-West2': {
    color: '#619E4D',
    displayName: 'Asian-West2 (Iraqi Jews)',
    coordinates: [33.7338975, 44.2668514]
  },
  'Asian-Southwest': {
    color: '#AED2A1',
    displayName: 'Asian-Southwest (Bedouin B, Saudi)',
    coordinates: [38.126015490103036, 29.82543957211467]
  },
  'Asian-Central-West1': {
    color: '#3c096c',
    displayName: 'Asian-Central-West1 (Bashkirs)',
    coordinates: [54.7655172, 56.8928702]
  },
  'Asian-Central-West2': {
    color: '#5a189a',
    displayName: 'Asian-Central-West2 (Turkmens)',
    coordinates: [61.23634, 35.79007]
  },
  'Asian-Central-East1': {
    color: '#7b2cbf',
    displayName: 'Asian-Central-East1 (Tajikistani Tajiks)',
    coordinates: [70.90225111807365, 39.01496597535389]
  },
  'Asian-Central-East2': {
    color: '#9d4edd',
    displayName: 'Asian-Central-East2 (Kyrgis)',
    coordinates: [74.38939, 41.47614]
  },
  'Asian-Central-East3': {
    color: '#c77dff',
    displayName: 'Asian-Central-East3 (Uyghur)',
    coordinates: [84.9992003, 39.6431508]
  },
  'Asian-Southcentral': {
    color: '#DF873D',
    displayName: 'Asian-Southcentral (Brahui)',
    coordinates: [67.51842, 29.14535]
  },
  'Asian-South': {
    color: '#A8551C',
    displayName: 'Asian-South (Gujarati C & D)',
    coordinates: [70.93262, 23.12273]
  },
  'Asian-East': {
    color: '#FEE196',
    displayName: 'Asian-East (Han, She, Tu)',
    coordinates: [114.32840906305368, 28.922358451689615]
  },
  'Asian-Southeast': {
    color: '#EDBB41',
    displayName: 'Asian-Southeast (Burmese, Cambodian)',
    coordinates: [99.08838259293204, 12.124533051696433]
  },
  'Slavic-Eastern': {
    color: '#2FFFFE',
    displayName: 'Slavic-Eastern (Belarusians, Ukrainians)',
    coordinates: [29.113184262727657, 51.50891846533939]
  },
  'Slavic-Southern': {
    color: '#77CBD0',
    displayName: 'Slavic-Southern (Bosnian, Serbian, Montenegrin)',
    coordinates: [19.33482, 43.66139]
  },
  'Baltic': {
    color: '#99BCC1',
    displayName: 'Baltic (Latvian, Estonian, Lithuanian)',
    coordinates: [24.78913, 57.44992]
  },
  'European-Northwest': {
    color: '#3B79BB',
    displayName: 'European-Northwest (Scott, French, English)',
    coordinates: [-2.19439, 50.95367]
  },
  'European-West': {
    color: '#97BDE2',
    displayName: 'European-West (Basque)',
    coordinates: [-5.95185, 43.21824]
  },
  'Scandanavian': {
    color: '#C3D5F5',
    displayName: 'Scandanavian (Swedes)',
    coordinates: [15.090094121104638, 60.39881320949709]
  },
  'Mediteranean-Eastern': {
    color: '#3A6CCD',
    displayName: 'Mediteranean-Eastern (Greeks, Albanians, Kosovo)',
    coordinates: [19.911093093263133, 41.988536580109745]
  },
  'Mediteranean-Western': {
    color: '#1E3C79',
    displayName: 'Mediteranean-Western (Spanish)',
    coordinates: [-1.11506, 38.34987]
  },
  'Siberian-West': {
    color: '#e73b4f',
    displayName: 'Siberian-West (Mansi)',
    coordinates: [63.282973833830184, 61.4335664222547]
  },
  'Siberian-Central': {
    color: '#eb584d',
    displayName: 'Siberian-Central (Nganasan)',
    coordinates: [137.69711705051637, 70.94561960019095]
  },
  'Siberian-East': {
    color: '#ef6752',
    displayName: 'Siberian-East (Yakut)',
    coordinates: [122.48940, 59.84239]
  },
  'Polar': {
    color: '#B87094',
    displayName: 'Polar (Aleutians, Eskimos)',
    coordinates: [-161.75702, 55.46236]
  },
  'Native-American-S': {
    color: '#671A3D',
    displayName: 'Native-American-S (Karitiana, Surui, & Quechua)',
    coordinates: [-57.30300, -17.18482]
  },
  'Native-American-C': {
    color: '#9A446D',
    displayName: 'Native-American-C (Mayans, Mixe)',
    coordinates: [-88.05296, 20.04479]
  },
  'African-North': {
    color: '#C23A28',
    displayName: 'African-North (Mozabite)',
    coordinates: [-1.88251, 34.92837]
  },
  'African-East': {
    color: '#991B11',
    displayName: 'African-East (Hadza, Luhya, Luo)',
    coordinates: [37.79287, -7.93040]
  },
  'African-West': {
    color: '#4F1106',
    displayName: 'African-West (Esan, Gambian, Yoruba)',
    coordinates: [-5.88478, 9.64562]
  }
}
/* eslint-enable quote-props */

export function getDisplayName (popName) {
  return (POPS_INFO[popName] && POPS_INFO[popName].displayName) || popName
}
// $charcoal: #264653ff;
// $persian-green: #2a9d8fff;
// $orange-yellow-crayola: #e9c46aff;
// $sandy-brown: #f4a261ff;
// $burnt-sienna: #e76f51ff;
