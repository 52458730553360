
export function formatPercentage (p, isPercentage) {
  const p100 = p * (isPercentage ? 1 : 100)
  let [n, decimals] = `${p100}`.split('.')
  if (!decimals) decimals = 0
  else if (+decimals > 0 && decimals.length > 2) {
    decimals = ~~(decimals / (10 ** (decimals.length - 2)))
    // decimals = Math.round(decimals/(10 ** (decimals.length - 1)))
  }
  if (+decimals <= 0) decimals = ''
  return +`${n}.${decimals}`
}

export function utf8ToB64 (str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

export function b64ToUtf8 (str) {
  return decodeURIComponent(escape(window.atob(str)))
}
