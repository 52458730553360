import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { report as previewReport } from './utils/preview-samples'
import { b64ToUtf8 } from './utils'
import App from './App'
// import reportWebVitals from './reportWebVitals'

function fixReport (report) {
  if (report && report.regions && report.regions.Oceanian) {
    report.regions.Oceania = report.regions.Oceanian
    delete report.regions.Oceanian
  }
}

fixReport(previewReport);

(() => {
  try {
    const url = new URL(document.location.href)
    let report = url && url.searchParams ? url.searchParams.get('report') : null
    if (report && report !== 'preview') {
      report = b64ToUtf8(report)
      report = JSON.parse(report)
      if (report.reportPage) report = report.reportPage
    } else if (report && report === 'preview') {
      report = previewReport
    }
    if (report) {
      fixReport(report)
      ReactDOM.render(
        <React.StrictMode>
          <App report={report} />
        </React.StrictMode>,
        document.getElementById('root')
      )
    }
  } catch (e) {
    console.error(e)
  }
  let report
  window.addEventListener('message', (event) => {
    console.log(event.data)
    if (!event.data.replace || event.data.reactBuildType) return
    if (event.data && typeof event.data === 'string') {
      try {
        const parsed = JSON.parse(event.data)
        if (parsed.preview) {
          report = previewReport
        } else if (parsed.reportPage) {
          report = parsed.reportPage
        }
        if (report) {
          fixReport(report)
          ReactDOM.render(
            <React.StrictMode>
              <App report={report} />
            </React.StrictMode>,
            document.getElementById('root')
          )
        }
      } catch (e) {
        console.error(e)
      }
    }
  },
  false
  )
})()

// ReactDOM.render(
//   <React.StrictMode>
//     <App report={previewReport} />
//   </React.StrictMode>,
//   document.getElementById('root')
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
