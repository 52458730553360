
function _getNode (tree, nodeName) {
  if (nodeName === tree.name) {
    return tree
  } else if (tree.children) {
    return tree.children
      .map(c => _getNode(c, nodeName))
      .find(a => a !== false && typeof a !== 'undefined')
  } else {
    return false
  }
}

function _hasNode (tree, nodeName) {
  return _getNode(tree, nodeName)
}

function _updatePercentage (node, percentage) {
  node.percentage = percentage
  if (node.parentNode) {
    node.parentNode.percentage = node.parentNode.children.reduce((a, b) => a + b.percentage, 0)
  }
  if (node.parentNode.parentNode) {
    _updatePercentage(node.parentNode, node.parentNode.percentage)
  }
}

function _sort (tree) {
  tree.children = tree.children.sort((a, b) => b.percentage - a.percentage)
  tree.children.forEach(n => _sort(n))
}

class Tree {
  constructor () {
    this.nodes = { name: 'root', children: [] }
  }

  addNode (node, parentNodeName = 'root') {
    const parentNode = parentNodeName === 'root' ? this.nodes : this.getNode(parentNodeName)
    if (!parentNode.children) {
      parentNode.children = []
    }
    if (!_hasNode(parentNode, node.name)) {
      parentNode.children.push(node)
      node.parentNode = parentNode
    }
    if (!node.percentage) {
      node.percentage = 0
    }
  }

  hasNode (nodeName) {
    return _hasNode(this.nodes, nodeName)
  }

  getNode (nodeName) {
    return _getNode(this.nodes, nodeName)
  }

  setNodePercentage (nodeName, percentage) {
    const node = this.getNode(nodeName)
    _updatePercentage(node, percentage)
  }

  sort () {
    _sort(this.nodes)
  }
}

function build (algorithmData, CLUSTERS, clusterInfo) {
  const tree = new Tree()

  CLUSTERS.forEach(c => {
    c.forEach((region, idx) => {
      const node = Object.assign({ name: region }, clusterInfo[region], { children: [] })
      node.displayName = node.displayName ? node.displayName : region
      if (idx === 0) {
        tree.addNode(node)
      } else {
        const parentNodeName = c[idx - 1]
        tree.addNode(node, parentNodeName)
      }
    })
  })

  for (const i in algorithmData) {
    // console.log(i, algorithmData[i])
    const percentage = algorithmData[i] * 100
    tree.setNodePercentage(i, percentage)
  }
  tree.sort()
  return tree.nodes
}

const defaultExport = {
  build,
  shouldShowChildren (node) {
    if (node.children && node.children.length > 1) {
      return true
    } else if (node.children.length === 1) {
      if (node.children[0].displayName !== node.displayName || defaultExport.shouldShowChildren(node.children[0])) {
        return true
      }
    }
    return false
  },
  getFlatTree (tree) {
    const self = defaultExport
    let flattenArr = [tree]
    if (tree.children) {
      tree.children.forEach(child => (flattenArr = flattenArr.concat(self.getFlatTree(child))))
    }
    return flattenArr
  },
  getTopParent (node) {
    const self = defaultExport
    if (node.parentNode && node.parentNode.name !== 'root') {
      return self.getTopParent(node.parentNode)
    } else {
      return node
    }
  }
}

export default defaultExport
