import React from 'react'
// import { findDOMNode } from 'react-dom'
import { createSunBurst } from './d3-sunburst'
import * as $ from 'jquery'

import './sunburst.scss'

class Sunburst extends React.Component {
  constructor (props) {
    super(props)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.createSunBurst = this.createSunBurst.bind(this)
    this.el = null
  }

  getColorsObj () {
    const tree = this.props.tree
    const colorsObj = {}
    tree.children.forEach(n => (colorsObj[n.name] = n.color))
    return colorsObj
  }

  updateDimensions () {
    // let w = window,
    //     d = document,
    //     documentElement = d.documentElement,
    //     body      = d.getElementsByTagName('body')[0] //,
    // width     = w.innerWidth || documentElement.clientWidth || body.clientWidth,
    // height    = w.innerHeight|| documentElement.clientHeight|| body.clientHeight

    const previousWidth = this.state.width
    const width = this.el.offsetWidth
    // console.log(previousWidth, width)
    if (previousWidth !== width) {
      $('#chart svg').remove()
      console.log('updateDimensions')
      this.createSunBurst()
      this.props.sizeUpdate()
    }
    // this.setState({ width: width, height: height })
  }

  createSunBurst () {
    const tree = this.props.tree
    const colors = this.getColorsObj()
    const setActive = this.props.setActive
    // const width     = findDOMNode(this).offsetWidth
    const width = this.el.offsetWidth
    this.setState({ width })
    createSunBurst(tree, colors, width, setActive)
  }

  render () {
    const width = this.state ? this.state.width : null
    return (
      <div id='main' ref={el => (this.el = el)}>
        {/* <div id='sequence'></div> */}
        <div width={width} id='chart'>
          <div id='explanation'>
            <span id='percentage' />
            <span id='explanation-text' />
            <span id='explanation-desc' />
          </div>
        </div>
      </div>
    )
  }

  componentDidMount () {
    // console.log('componentDidMount')
    this.createSunBurst()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate (prevProps) {
    if (this.props.tree !== prevProps.tree) {
      $('#chart svg').remove()
      this.createSunBurst()
    }
  }
}

export default Sunburst
