
/* eslint-disable quote-props */
// Alain's results
// export const report = {
//   'populations': {
//     'Asian-West1': 0.020213,
//     'Asian-West2': 0.033257,
//     'Asian-Southwest': 0.016661,
//     'Asian-Central-West1': 0.048110,
//     'Asian-Central-West2': 0.026734,
//     'Asian-Central-East1': 0.000010,
//     'Asian-Central-East2': 0.000010,
//     'Asian-Central-East3': 0.011919,
//     'Asian-Southcentral': 0.000010,
//     'Asian-South': 0.000010,
//     'Slavic-Eastern': 0.000010,
//     'Slavic-Southern': 0.153018,
//     'Baltic': 0.000010,
//     'European-Northwest': 0.085526,
//     'European-West': 0.000010,
//     'Scandanavian': 0.000010,
//     'Mediteranean-Eastern': 0.000010,
//     'Mediteranean-Western': 0.392943,
//     'Siberian-West': 0.000010,
//     'Siberian-Central': 0.000010,
//     'Siberian-East': 0.000010,
//     'Polar': 0.000010,
//     'Native-American-S': 0.014472,
//     'Native-American-C': 0.191413,
//     'Asian-East': 0.000010,
//     'Asian-Southeast': 0.000010,
//     'Oceanian': 0.000010,
//     'African-North': 0.000010,
//     'African-East': 0.005555,
//     'African-West': 0.000010
//   },
//   'regions': {
//     'West-Asia': 0.070131,
//     'Central-Asia': 0.086783,
//     'South-Asia': 2e-05,
//     'Europe': 0.631537,
//     'Siberia': 3e-05,
//     'Americas': 0.205895,
//     'East-Asia': 2e-05,
//     'Oceania': 0.000010,
//     'Africa': 0.005575
//   }
// }

export const sample1 = {
  'Asian-West1': 0.020213,
  'Asian-West2': 0.033257,
  'Asian-Southwest': 0.016661,
  'Asian-Central-West1': 0.048110,
  'Asian-Central-West2': 0.026734,
  'Asian-Central-East1': 0.000010,
  'Asian-Central-East2': 0.000010,
  'Asian-Central-East3': 0.011919,
  'Asian-Southcentral': 0.000010,
  'Asian-South': 0.000010,
  'Slavic-Eastern': 0.000010,
  'Slavic-Southern': 0.153018,
  'Baltic': 0.000010,
  'European-Northwest': 0.085526,
  'European-West': 0.000010,
  'Scandanavian': 0.000010,
  'Mediteranean-Eastern': 0.000010,
  'Mediteranean-Western': 0.392943,
  'Siberian-West': 0.000010,
  'Siberian-Central': 0.000010,
  'Siberian-East': 0.000010,
  'Polar': 0.000010,
  'Native-American-S': 0.014472,
  'Native-American-C': 0.191413,
  'Asian-East': 0.000010,
  'Asian-Southeast': 0.000010,
  'Oceanian': 0.000010,
  'African-North': 0.000010,
  'African-East': 0.005555,
  'African-West': 0.000010
}

export const report = {
  'populations': {
    'Asian-West1': 0.000010,
    'Asian-West2': 0.000010,
    'Asian-Southwest': 0.000010,
    'Asian-Central-West1': 0.000010,
    'Asian-Central-West2': 0.000507,
    'Asian-Central-East1': 0.000010,
    'Asian-Central-East2': 0.000010,
    'Asian-Central-East3': 0.000010,
    'Asian-Southcentral': 0.000010,
    'Asian-South': 0.000010,
    'Slavic-Eastern': 0.141083,
    'Slavic-Southern': 0.000010,
    'Baltic': 0.000010,
    'European-Northwest': 0.194352,
    'European-West': 0.000010,
    'Scandanavian': 0.201094,
    'Mediteranean-Eastern': 0.059421,
    'Mediteranean-Western': 0.400799,
    'Siberian-West': 0.000010,
    'Siberian-Central': 0.000010,
    'Siberian-East': 0.000010,
    'Polar': 0.000010,
    'Native-American-S': 0.002514,
    'Native-American-C': 0.000010,
    'Asian-East': 0.000010,
    'Asian-Southeast': 0.000010,
    'Oceanian': 0.000010,
    'African-North': 0.000010,
    'African-East': 0.000010,
    'African-West': 0.000010
  },
  'regions': {
    'W_Asian_&_N_African': 4e-05,
    'C&SC_Asian': 0.000557,
    'S_Asian': 0.000010,
    'European': 0.536559,
    'Mediteranean': 0.46022,
    'Siberian&NativeAm': 0.002564,
    'E_Asian': 2e-05,
    'Oceanian': 0.000010,
    'African': 2e-05
  },
  'distances': {
    'Sintashta_MLBA': 54.97047,
    'Chuvashes': 55.54428,
    'Andronovo_MLBA': 57.06231,
    'Yamna_Karagash': 59.31612,
    'Finns': 59.60466,
    'Turk_Balekesir': 60.38934,
    'Mezhovskaya_RISE523': 61.29575,
    'Polish': 65.17193,
    'Italians': 65.23235,
    'Scottish': 65.29234,
    'Karasuk_Avg': 67.62305,
    'Balkar': 78.31695,
    'Ossetians': 80.09414,
    'Druze': 80.09736,
    'Assyrians': 82.00150,
    'Kurds_Iraq': 83.62499,
    'Saka_Eastern_RISE602': 84.99969,
    'Armenians': 85.03761,
    'Iranians_South': 85.20354,
    'Cree_Amerindians': 85.76587,
    'Kurds': 86.45641,
    'Turkey_Trabazon': 86.54164,
    'Jordanians': 87.41366,
    'Tajik_Rushan': 87.44236,
    'Saka_Eastern_RISE601': 87.52571,
    'Andamanese': 93.78874,
    'Punjabi': 95.81806,
    'Kazakh': 98.70152,
    'Mongola': 100.30099
  },
  'distances_sub_regions': {
    'Turk_Balekesir': 54.65436,
    'Italians': 55.24335,
    'Yamna_Karagash': 61.20470,
    'Druze': 61.73787,
    'Sintashta_MLBA': 63.13209,
    'Andronovo_MLBA': 64.36159,
    'Mezhovskaya_RISE523': 65.27954,
    'Karasuk_Avg': 67.02638,
    'Jordanians': 67.03715,
    'Assyrians': 67.57113,
    'Finns': 68.46897,
    'Iranians_South': 69.23184,
    'Cree_Amerindians': 71.27169,
    'Kurds_Iraq': 71.31169,
    'Polish': 71.54968,
    'Armenians': 72.28743,
    'Chuvashes': 72.85670,
    'Kurds': 73.12276,
    'Ossetians': 73.15344,
    'Tajik_Rushan': 77.10559,
    'Scottish': 77.25477,
    'Turkey_Trabazon': 77.95463,
    'Saka_Eastern_RISE602': 78.68407,
    'Balkar': 78.77915,
    'Saka_Eastern_RISE601': 79.54186,
    'Punjabi': 84.08367,
    'Andamanese': 87.63749,
    'Kazakh': 93.54592,
    'Mongola': 94.60430
  }
}

export const report2 = {
  'populations': {
    'Asian-West1': 0.020213,
    'Asian-West2': 0.033257,
    'Asian-Southwest': 0.016661,
    'Asian-Central-West1': 0.048110,
    'Asian-Central-West2': 0.026734,
    'Asian-Central-East1': 0.000010,
    'Asian-Central-East2': 0.000010,
    'Asian-Central-East3': 0.011919,
    'Asian-Southcentral': 0.000010,
    'Asian-South': 0.000010,
    'Slavic-Eastern': 0.000010,
    'Slavic-Southern': 0.153018,
    'Baltic': 0.000010,
    'European-Northwest': 0.085526,
    'European-West': 0.000010,
    'Scandanavian': 0.000010,
    'Mediteranean-Eastern': 0.000010,
    'Mediteranean-Western': 0.392943,
    'Siberian-West': 0.000010,
    'Siberian-Central': 0.000010,
    'Siberian-East': 0.000010,
    'Polar': 0.000010,
    'Native-American-S': 0.014472,
    'Native-American-C': 0.191413,
    'Asian-East': 0.000010,
    'Asian-Southeast': 0.000010,
    'Oceanian': 0.000010,
    'African-North': 0.000010,
    'African-East': 0.005555,
    'African-West': 0.000010
  },
  'regions': {
    'W_Asian_&_N_African': 0.070141,
    'C&SC_Asian': 0.086793,
    'S_Asian': 0.000010,
    'European': 0.238584,
    'Mediteranean': 0.392953,
    'Siberian&NativeAm': 0.205925,
    'E_Asian': 2e-05,
    'Oceanian': 0.000010,
    'African': 0.005565
  },
  'distances': {
    'Turk_Balekesir': 38.83669,
    'Italians': 50.65749,
    'Chuvashes': 51.24278,
    'Karasuk_Avg': 57.05299,
    'Cree_Amerindians': 57.53187,
    'Ossetians': 57.56371,
    'Druze': 59.82501,
    'Assyrians': 61.20872,
    'Saka_Eastern_RISE602': 61.59485,
    'Saka_Eastern_RISE601': 61.65843,
    'Iranians_South': 62.39547,
    'Kurds_Iraq': 62.51535,
    'Balkar': 62.73326,
    'Mezhovskaya_RISE523': 63.66137,
    'Yamna_Karagash': 64.22506,
    'Kurds': 64.69779,
    'Armenians': 64.87176,
    'Jordanians': 66.77651,
    'Turkey_Trabazon': 67.74575,
    'Andronovo_MLBA': 68.18388,
    'Sintashta_MLBA': 68.51238,
    'Tajik_Rushan': 70.91269,
    'Kazakh': 75.24484,
    'Punjabi': 78.17136,
    'Finns': 79.54976,
    'Andamanese': 80.29316,
    'Mongola': 83.01605,
    'Bengali': 87.13905,
    'Polish': 88.64955
  },
  'distances_sub_regions': {
    'French': 38.13953,
    'Druze': 47.06368,
    'Italians': 47.89243,
    'Cree_Amerindians': 49.94945,
    'Assyrians': 51.63907,
    'Jordanians': 52.43109,
    'Iranians_South': 52.81923,
    'Kurds_Iraq': 54.60773,
    'Ossetians': 56.46470,
    'Kurds': 56.71059,
    'Armenians': 58.33422,
    'Yamna_Karagash': 59.16097,
    'Chuvashes': 60.14719,
    'Balkar': 62.99408,
    'Saka_Eastern_RISE601': 63.64660,
    'Tajik_Rushan': 64.52971,
    'Turkey_Trabazon': 65.40296,
    'Saka_Eastern_RISE602': 65.48723,
    'Karasuk_Avg': 67.05804,
    'Finns': 68.56138,
    'Punjabi': 71.09916,
    'Polish': 73.54609,
    'Mezhovskaya_RISE523': 74.98838,
    'Kazakh': 77.80866,
    'Andamanese': 78.53819,
    'Andronovo_MLBA': 80.87560,
    'Sintashta_MLBA': 81.69823,
    'Mongola': 84.09649,
    'Bengali': 85.30027
  }
}
// COLOUR TEST --> uncomment bellow to test colours

// export const sample1 = {
//   'Asian-West1': 0.032,
//   'Asian-West2': 0.032,
//   'Asian-Southwest': 0.032,
//   'Asian-Central-West1': 0.032,
//   'Asian-Central-West2': 0.032,
//   'Asian-Central-East1': 0.032,
//   'Asian-Central-East2': 0.032,
//   'Asian-Central-East3': 0.032,
//   'Asian-Southcentral': 0.032,
//   'Asian-South': 0.032,
//   'Slavic-Eastern': 0.032,
//   'Slavic-Southern': 0.032,
//   'Baltic': 0.032,
//   'European-Northwest': 0.032,
//   'European-West': 0.032,
//   'Scandanavian': 0.032,
//   'Mediteranean-Eastern': 0.032,
//   'Mediteranean-Western': 0.032,
//   'Siberian-West': 0.032,
//   'Siberian-Central': 0.032,
//   'Siberian-East': 0.032,
//   'Polar': 0.032,
//   'Native-American-S': 0.032,
//   'Native-American-C': 0.032,
//   'Asian-East': 0.032,
//   'Asian-Southeast': 0.032,
//   'Oceanian': 0.032,
//   'African-North': 0.032,
//   'African-East': 0.032,
//   'African-West': 0.072
// }
//
// export const report = {
//   'populations': {
//     'Asian-West1': 0.032,
//     'Asian-West2': 0.032,
//     'Asian-Southwest': 0.032,
//     'Asian-Central-West1': 0.032,
//     'Asian-Central-West2': 0.032,
//     'Asian-Central-East1': 0.032,
//     'Asian-Central-East2': 0.032,
//     'Asian-Central-East3': 0.032,
//     'Asian-Southcentral': 0.032,
//     'Asian-South': 0.032,
//     'Slavic-Eastern': 0.032,
//     'Slavic-Southern': 0.032,
//     'Baltic': 0.032,
//     'European-Northwest': 0.032,
//     'European-West': 0.032,
//     'Scandanavian': 0.032,
//     'Mediteranean-Eastern': 0.032,
//     'Mediteranean-Western': 0.032,
//     'Siberian-West': 0.032,
//     'Siberian-Central': 0.032,
//     'Siberian-East': 0.032,
//     'Polar': 0.032,
//     'Native-American-S': 0.032,
//     'Native-American-C': 0.032,
//     'Asian-East': 0.032,
//     'Asian-Southeast': 0.032,
//     'Oceanian': 0.032,
//     'African-North': 0.032,
//     'African-East': 0.032,
//     'African-West': 0.072
//   },
//   'regions': {
//     'West-Asia': 0.108,
//     'Central-Asia': 0.108,
//     'South-Asia': 0.108,
//     'Europe': 0.108,
//     'Siberia': 0.108,
//     'Americas': 0.108,
//     'East-Asia': 0.108,
//     'Oceania': 0.108,
//     'Africa': 0.136
//   }
// }
/* eslint-enable quote-props */
